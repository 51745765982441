import React from "react";
import hero from "../../../static/svg/hero.svg";
import mail from "../../../static/svg/mail.svg";
import send from "../../../static/svg/send.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import "./Home.scss";

const Home = () => {
  return (
    <div className="hero main-hero is-fullheight is-default is-bold section-1">
      <img src={hero} alt="" className="hero-shape is-active" />
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered hero-caption">
            <div className="column is-7">
              <h1 className="title is-2 is-bold is-active">Adriano Righi</h1>
              <h2 className="subtitle is-6 is-active">
                Engenheiro de Software
                <br />
                Tech Lead
                <br />
                DevOps
              </h2>
            </div>
            <div className="column is-4 is-offset-1">
              {/* <h1 className="title is-3 is-bold has-text-white">Newsletter</h1>
              <h2 className="subtitle is-6 has-text-light">
                Newsletter com dicas de tecnologia, carreira e finanças para
                devs e freelancers.
              </h2>
              <div className="control">
                <form
                  className="ml-block-form"
                  action="https://app.mailerlite.com/webforms/submit/u7u7w0"
                  data-code="u7u7w0"
                  method="post"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <input
                    type="email"
                    name="fields[email]"
                    autoComplete="email"
                    className="input is-rounded"
                    placeholder="E-mail"
                  />
                  <input type="hidden" name="ml-submit" value="1"></input>
                  <button className="button" type="submit">
                    <img src={send} alt="" />
                  </button>
                  <div className="form-icon">
                    <img src={mail} alt="" />
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hero-foot">
        <nav className="tabs">
          <div className="container">
            <ul className="socials">
              <li>
                <a className="social" href="https://instagram.com/adrianorighi" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a className="social" href="https://github.com/adrianorighi" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </li>
              <li>
                <a className="social" href="https://linkedin.com/in/adrianorighi" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </li>
              <li>
                <a className="social" href="https://facebook.com/adrianorighi" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Home;
