import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from '../components/Seo/Seo';
import Home from '../components/Home';
import Posts from '../components/Posts';
import About from '../components/About';
import 'minireset.css';

export default class Index extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <Seo />
        <Home />
        <Posts showAllBtn={true} data={data}/>
        <About />
      </Layout>
    )
  }
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [fields___prefix], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
      ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            image
            date(formatString: "DD/MM/YYYY")
            description
          }
        }
      }
    }
  }
`;
