import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { useHasScroll } from "has-scroll-hook";
import { menuToggle } from "./MenuToggle";
import logo from "../../../static/svg/logo.svg";
import "./Header.scss";

export default function Header() {
  const hasScroll = useHasScroll();

  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          site {
            siteMetadata {
              title
              siteUrl
            }
          }
        }
      `}
      render={(data) => (
        <header
          className={`navbar is-transparent is-fixed-top ${
            hasScroll ? "is-scrolled" : ""
          }`}
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <a className="navbar-item" href={data.site.siteMetadata.siteUrl}>
              <img src={logo} alt="logo"/>
            </a>

            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              onClick={menuToggle}
              data-target="navMenu"
              href="#"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div className="navbar-menu" id="navMenu">
            <div className="navbar-end">
              <div className="navbar-item is-hidden-mobile">
                <div className="ninja-toggle">
                  <label htmlFor="theme-toggle-mobile">
                    <span className="track">
                      <span className="track-inner"></span>
                      <span className="track-knob">
                        <i className="sun" data-feather="sun"></i>
                        <i className="moon" data-feather="moon"></i>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              <a className="navbar-item" href="#blog">Blog</a>
              <a className="navbar-item" href="#about">Sobre</a>
            </div>
          </div>
        </header>
      )}
    />
  );
}
